
import View from 'components/view';

const HealthAdvice = r => require.ensure([], () => r(require('@/pages/healthAdvice/list.vue')), 'HealthAdvice');
const HealthAdviceDetail = r => require.ensure([], () => r(require('@/pages/healthAdvice/detail.vue')), 'HealthAdviceDetail');
export default [
    {
        path: '/health-advice',
        component: View,
        children: [
            {
                path: 'index',
                component: HealthAdvice
            },
            {
                path: 'detail',
                component: HealthAdviceDetail
            }
        ]
    }
];


import View from 'components/view';

const healthRecord = r => require.ensure([], () => r(require('@/pages/healthRecord/index')), 'healthRecord');
const verfyCode = r => require.ensure([], () => r(require('@/pages/healthRecord/verify-code')), 'verfyCode');

export default [
    {
        path: '/healthRecord',
        component: View,
        children: [
            {
                path: 'index',
                component: healthRecord
            },
            {
                path: 'verfyCode',
                component: verfyCode
            }
        ]
    }
];

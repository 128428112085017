
import View from 'components/view';

const MwapSkip = r => require.ensure([], () => r(require('@/pages/jklj/mwap-skip')), 'MwapSkip');
const PortalSkip = r => require.ensure([], () => r(require('@/pages/jklj/portal-skip')), 'PortalSkip');
const JkljIndex = r => require.ensure([], () => r(require('@/pages/jklj/jklj-index')), 'JkljIndex');
const JkljNewIndex = r => require.ensure([], () => r(require('@/pages/jklj/jklj-new-index')), 'JkljNewIndex');

const BenefitOld = r => require.ensure([], () => r(require('@/pages/jklj/benefit-old')), 'BenefitOld');
const PhoneList = r => require.ensure([], () => r(require('@/pages/jklj/phone-list')), 'PhoneList');
const YiMiao = r => require.ensure([], () => r(require('@/pages/yimiao/yimiao')), 'YiMiao');

export default [
    {
        path: '/health-jklj',
        component: View,
        children: [
            {
                path: 'index',
                component: JkljIndex
            },
            {
                path: 'new-index',
                component: JkljNewIndex
            },
            {
                path: 'mwap-skip',
                component: MwapSkip
            },
            {
                path: 'portal-page',
                component: PortalSkip
            },
            {
                path: 'benefit-old',
                component: BenefitOld
            },
            {
                path: 'phone-list',
                component: PhoneList
            },
            {
                path: 'yimiao',
                component: YiMiao
            }
        ]
    }
];

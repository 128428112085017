
import Header from './header';

const components = [
    Header
];

const install = Vue => {
    components.forEach(component => (
        Vue.component(component.name, component)
    ));
};

export default {
    install,
    Header
};

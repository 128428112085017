
import View from 'components/view';

const QuestionList = r => require.ensure([], () => r(require('@/pages/questions/question-list')), 'QuestionList');
const QuestionDetail = r => require.ensure([], () => r(require('@/pages/questions/question-detail')), 'QuestionDetail');
const QuestionSearch = r => require.ensure([], () => r(require('@/pages/questions/question-search')), 'QuestionSearch');

export default [
    {
        path: '/question',
        component: View,
        children: [
            {
                path: 'list',
                component: QuestionList
            },
            {
                path: 'detail',
                component: QuestionDetail
            },       
            {
                path: 'search',
                component: QuestionSearch
            }
        ]
    }
];

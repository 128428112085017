
import View from 'components/view';

const Complaints = r => require.ensure([], () => r(require('@/pages/complaints')), 'complaints');
const BasicMes = r => require.ensure([], () => r(require('@/pages/complaints/basicMes')), 'basicMes');
const Content = r => require.ensure([], () => r(require('@/pages/complaints/content')), 'content');
const Place = r => require.ensure([], () => r(require('@/pages/complaints/place')), 'place');
const PartiesMes = r => require.ensure([], () => r(require('@/pages/complaints/partiesMes')), 'partiesMes');
const Introduce = r => require.ensure([], () => r(require('@/pages/complaints/introduce')), 'Introduce');
const GetPhoto = r => require.ensure([], () => r(require('@/pages/complaints/getPhoto')), 'GetPhoto');
const UploadPhoto = r => require.ensure([], () => r(require('@/pages/complaints/uploadPhoto')), 'UploadPhoto');

const RecordList = r => require.ensure([], () => r(require('@/pages/complaints/recordList')), 'RecordList');
const RecordDetatil = r => require.ensure([], () => r(require('@/pages/complaints/recordDetail')), 'RecordDetatil');

export default [
    {
        path: '/complaints',
        component: View,
        children: [
            {
                path: 'index',
                component: Complaints
            },
            {
                path: 'introduce',
                component: Introduce
            },
            {
                path: 'basic',
                component: BasicMes
            },
            {
                path: 'content',
                component: Content,
                meta: {
                    keepalive: true
                }
            },
            {
                path: 'place',
                component: Place
            },
            {
                path: 'partiesMes',
                component: PartiesMes
            },
            {
                path: 'getPhoto',
                component: GetPhoto
            },
            {
                path: 'uploadPhoto',
                component: UploadPhoto
            },
            {
                path: 'recordList',
                component: RecordList
            },
            {
                path: 'recordDetail/:id',
                component: RecordDetatil
            }
        ]
    }
];

export default {
    baseUrl: '',
    historyUrl: '',
    guahaoUrl: 'https://jklj.wy.guahaoe.com',
    wapqlcUrl: 'http://mwap-hz-jkt.guahaoe.com',
    h5Url: 'http://h5-stag.health.ljjk.org.cn',
    sysTokenUrl: 'http://hlj.guahaoe.com/h5healthhlj/getToken',
    sysWriteTokenUrl: 'http://hlj.guahaoe.com/h5healthhlj/login',
    logoutUrl: 'https://account-h5.guahaoe.com/ac/logout?clientId=81596bc9dd56413c982dd3b7bce4a2db'
};


import View from 'components/view';

const NoticeList = r => require.ensure([], () => r(require('@/pages/notice/notice-list')), 'NoticeList');
const NoticeDetail = r => require.ensure([], () => r(require('@/pages/notice/notice-detail')), 'NoticeDetail');
export default [
    {
        path: '/notice',
        component: View,
        children: [
            {
                path: 'index',
                component: NoticeList
            },
            {
                path: 'detail',
                component: NoticeDetail
            }
        ]
    }
];


import View from 'components/view';

const Index = r => require.ensure([], () => r(require('@/pages/hljXG/index')), 'index');
const HospitalList = r => require.ensure([], () => r(require('@/pages/hljXG/hospital-list')), 'hospital-list');
const FeverClinic = r => require.ensure([], () => r(require('@/pages/hljXG/fever-clinic')), 'FeverClinic');
const BuyMedicine = r => require.ensure([], () => r(require('@/pages/hljXG/buy-medicine')), 'BuyMedicine');
const PhoneConsultation = r => require.ensure([], () => r(require('@/pages/hljXG/phone-consultation')), 'PhoneConsultation');
const OnlineConsultation = r => require.ensure([], () => r(require('@/pages/hljXG/online-consultation')), 'OnlineConsultation');

export default [
    {
        path: '/hlj-xg',
        component: View,
        children: [
            {
                path: 'index',
                component: Index
            },
            {
                path: 'hospital-list',
                component: HospitalList
            },
            {
                path: 'fever-clinic',
                component: FeverClinic
            },
            {
                path: 'buy-medicine',
                component: BuyMedicine
            },
            {
                path: 'phone-consultation',
                component: PhoneConsultation
            },
            {
                path: 'online-consultation',
                component: OnlineConsultation
            }
        ]
    }
];

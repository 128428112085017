import Vue from 'vue';
import VueRouter from 'vue-router';
import Components from 'components/index';

import 'components/minitUi';

import { Toast, MessageBox, Indicator, InfiniteScroll, Popup } from 'mint-ui';
import Vant from 'vant';
import 'vant/lib/index.less';
import routes from './router';
import './utils/rem';
import './config';
import './assets/less/normalize';
import './assets/less/common';
import './assets/less/minituUi';

import store from './store';

Vue.use(VueRouter);

Vue.use(Vant);
Vue.use(Components);
Vue.use(InfiniteScroll);
Vue.use(Popup);

Vue.prototype.$toast = Toast;
Vue.prototype.$messageBox = MessageBox;
Vue.prototype.$indicator = Indicator;

Vue.config.ignoredElements = ['wx-open-launch-weapp'];
const router = new VueRouter({
    mode: 'history',
    routes
});
new Vue({ store, router }).$mount('#app');


import View from 'components/view';
import Complaints from './complaints';
import HospitalList from './hospitalList';
import Hospital from './hospital';
import HealthCard from './healthCard';
import Epidemic from './epidemic';
import HealthAdvice from './healthAdvice';
import Jklj from './jklj';
import Notice from './notice';
import User from './user';
import Questions from './questions';
import Actions from './healthActions';
import OldActions from './wisdomHelpsTheAged';
import HljXG from './hljXG';
import healthRecord from './healthRecord';
import healthAssessment from './healthAssessment';

// 登录
const Login = r => require.ensure([], () => r(require('@/pages/login')), 'login');

// 404
const NotFound = r => require.ensure([], () => r(require('@/pages/notfound')), 'not-found');

// skip
const Skip = r => require.ensure([], () => r(require('@/pages/skip')), 'Skip');


export default [
    ...Complaints,
    ...HospitalList,
    ...Hospital,
    ...HealthCard,
    ...Epidemic,
    ...HealthAdvice,
    ...Jklj,
    ...Notice,
    ...User,
    ...Questions,
    ...Actions,
    ...OldActions,
    ...HljXG,
    ...healthRecord,
    ...healthAssessment,
    {
        path: '/',
        component: View,
        meta: {
            title: '首页'
        },
        children: [
            {
                path: 'login',
                component: Login,
                meta: {
                    title: '登录'
                }
            },
            {
                path: 'skip',
                component: Skip,
                meta: {
                    title: '跳转'
                }
            }
        ]
    },
    {
        path: '*',
        component: NotFound,
        meta: {
            title: '404'
        }
    }
];

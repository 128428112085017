
import View from 'components/view';

const UserIndex = r => require.ensure([], () => r(require('@/pages/user/user-index.vue')), 'UserIndex');
const UserIdCard = r => require.ensure([], () => r(require('@/pages/user/user-idcard.vue')), 'UserIdCard');
const UserSet = r => require.ensure([], () => r(require('@/pages/user/user-set.vue')), 'UserSet');
const UserPhone = r => require.ensure([], () => r(require('@/pages/user/user-phone.vue')), 'UserPhone');
const UserPassword = r => require.ensure([], () => r(require('@/pages/user/user-password.vue')), 'UserPassword');
const UserInformation = r => require.ensure([], () => r(require('@/pages/user/user-information.vue')), 'UserInformation');
const UserPatientList = r => require.ensure([], () => r(require('@/pages/user/user-patientList.vue')), 'UserPatientList');
const UserAddPatient = r => require.ensure([], () => r(require('@/pages/user/user-addPatient.vue')), 'UserAddPatient');
const UserLogin = r => require.ensure([], () => r(require('@/pages/user/certification/login.vue')), 'UserLogin');
const UserRegisterJudgePhone = r => require.ensure([], () => r(require('@/pages/user/certification/register-judge-phone.vue')), 'UserRegisterJudgePhone');
const UserRegisterGetCode = r => require.ensure([], () => r(require('@/pages/user/certification/register-get-code.vue')), 'UserRegisterGetCode');
const UserRegisterPassword = r => require.ensure([], () => r(require('@/pages/user/certification/register-password.vue')), 'UserRegisterPassword');
const UserForgetGetCode = r => require.ensure([], () => r(require('@/pages/user/certification/forget-get-code.vue')), 'UserForgetGetCode');
const UserForgetPassword = r => require.ensure([], () => r(require('@/pages/user/certification/forget-password.vue')), 'UserForgetPassword');
const TextTreaty = r => require.ensure([], () => r(require('@/pages/user/certification/text-treaty.vue')), 'TextTreaty');
const textAgreement = r => require.ensure([], () => r(require('@/pages/user/certification/text-agreement.vue')), 'textAgreement');
const userCancelWhy = r => require.ensure([], () => r(require('../../pages/user/user-cancelwhy.vue')), 'userCancelWhy');
const userCancel = r => require.ensure([], () => r(require('@/pages/user/user-cancel.vue')), 'userCancel');
const userCancelKnow = r => require.ensure([], () => r(require('@/pages/user/user-cancelknow.vue')), 'userCancelKnow');
const TextPrivate = r => require.ensure([], () => r(require('@/pages/user/certification/text-private.vue')), 'TextPrivate');


export default [
    {
        path: '/user-center',
        component: View,
        children: [
            {
                path: 'index',
                component: UserIndex
            },
            {
                path: 'idcard',
                component: UserIdCard
            },
            {
                path: 'set',
                component: UserSet
            },
            {
                path: 'phone',
                component: UserPhone
            },
            {
                path: 'password',
                component: UserPassword
            },
            {
                path: 'cancelwhy',
                component: userCancelWhy
            },
            {
                path: 'cancelknow',
                component: userCancelKnow
            },
            {
                path: 'private-text',
                component: TextPrivate
            },
            {
                path: 'cancel',
                component: userCancel
            },
            {
                path: 'information',
                component: UserInformation
            },
            {
                path: 'patient-list',
                component: UserPatientList
            },
            {
                path: 'patient-add',
                component: UserAddPatient
            },
            {
                path: 'login',
                component: UserLogin
            },
            {
                path: 'agreement-text',
                component: textAgreement
            },
            {
                path: 'treaty-text',
                component: TextTreaty
            },
            {
                path: 'register/judge-phone',
                component: UserRegisterJudgePhone
            },
            {
                path: 'register/get-code',
                component: UserRegisterGetCode
            },
            {
                path: 'register/password',
                component: UserRegisterPassword
            },
            {
                path: 'forget/get-code',
                component: UserForgetGetCode
            },
            {
                path: 'forget/password',
                component: UserForgetPassword
            }
        ]
    }
];

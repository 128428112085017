
import View from 'components/view';

const ActionChannel = r => require.ensure([], () => r(require('@/pages/healthActions/action-channel.vue')), 'ActionChannel');
const ActionList = r => require.ensure([], () => r(require('@/pages/healthActions/action-list.vue')), 'ActionList');
const ActionDetail = r => require.ensure([], () => r(require('@/pages/healthActions/action-detail')), 'ActionDetail');

export default [
    {
        path: '/health-action',
        component: View,
        children: [
            {
                name: 'healthActionIndex',
                path: 'index',
                component: ActionList
            },
            {
                path: 'detail',
                component: ActionDetail
            },
            {
                path: 'channel',
                component: ActionChannel
            }
        ]
    }
];

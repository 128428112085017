<template>
    <div class="header">
        <header>
            <div>{{ title }}</div>
        </header>
    </div>
</template>

<script>
export default {
    name: 'VmHeader',
    props: {
        // 标题
        title: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {};
    }
};
</script>
<style lang='less' scoped>

</style>


import dev from './config.dev';
import prod from './config.prod';
import test from './config.test';

const { host } = window.location;

let config = dev;
if (host === 'h5-stag.health.ljjk.org.cn') {
    config = test;
} else if (host === 'h5.health.ljjk.org.cn') {
    config = prod;
}

const ret = config;

export default ret;

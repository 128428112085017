let baseUrl = '';
let historyUrl = '';
const { host } = window.location;
if (host !== 'h5-stag.health.ljjk.org.cn' || host !== 'h5.health.ljjk.org.cn') {
    // baseUrl = 'http://h5.health.ljjk.org.cn';
    baseUrl = 'https://ljw-web-qa1.guahao-test.com';
    // historyUrl = 'http://192.168.99.118:2002';
    historyUrl = 'http://complete-flow-portal-web-qa1.guahao-test.com';
} 
// baseUrl = 'http://h5.health.ljjk.org.cn';
export default {
    baseUrl,
    historyUrl,
    guahaoUrl: 'http://c.guahao-test.com',
    h5Url: 'http://192.168.99.184:8001',
    wapqlcUrl: 'http://wapqlc.health.ljjk.org.cn',
    sysTokenUrl: 'http://hlj.guahaoe.com/h5healthhlj/getToken',
    sysWriteTokenUrl: 'http://hlj.guahaoe.com/h5healthhlj/login',
    logoutUrl: 'http://account-h5.guahao-test.com/ac/logout?clientId=68da780d4c40485687e7b45e4d25596e'
};

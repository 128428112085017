
import View from 'components/view';

const HospitalList = r => require.ensure([], () => r(require('@/pages/hospitalList/hospital-list.vue')), 'HospitalList');

export default [
    {
        path: '/hospital-list',
        component: View,
        children: [
            {
                path: 'index',
                component: HospitalList
            }
        ]
    }
];

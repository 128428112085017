import types from '../types';

export const getSelfInfo = ({ commit }, data) => commit(types.SELFINFO, data);
export const getDetailPlace = ({ commit }, data) => commit(types.PLACE, data);
export const getPersonHealthCard = ({ commit }, data) => commit(types.HEALTH_CARD, data);
export const getPartiesMes = ({ commit }, data) => commit(types.PARTIES_MES, data);
export const getFileList = ({ commit }, data) => commit(types.FILE_LIST, data);
export const getFileImages = ({ commit }, data) => commit(types.FILE_IMAGES, data);
export const getSearchValue = ({ commit }, data) => commit(types.SEARCH_VALUE, data);

export default {
    getDetailPlace,
    getPartiesMes,
    getSelfInfo,
    getFileList,
    getFileImages,
    getPersonHealthCard,
    getSearchValue
};

import types from '../types';

export default {
    [types.PLACE](state, data) {
        state.detailPlace = data;
    },
    [types.SELFINFO](state, data) {
        state.selfInfo = data;
    },
    [types.PARTIES_MES](state, data) {
        state.partiesMes = data;
    },
    [types.FILE_LIST](state, data) {
        state.fileList = data;
    },
    [types.FILE_IMAGES](state, data) {
        state.fileImages = data;
    },
    [types.HEALTH_CARD](state, data) {
        state.healthCard = data;
    },
    [types.SEARCH_VALUE](state, data) {
        state.searchValue = data;
    }
};


import View from 'components/view';

const HospitalList = r => require.ensure([], () => r(require('@/pages/hospital/hospital-list.vue')), 'HospitalList');
const HospitalDetail = r => require.ensure([], () => r(require('@/pages/hospital/hospital-detail.vue')), 'HospitalDetail');
const DoctorList = r => require.ensure([], () => r(require('@/pages/hospital/doctor-list.vue')), 'DoctorList');
const DoctorDetail = r => require.ensure([], () => r(require('@/pages/hospital/doctor-detail.vue')), 'DoctorDetail');
const ChannelList = r => require.ensure([], () => r(require('@/pages/hospital/channel-list.vue')), 'ChannelList');
const ArticleDetail = r => require.ensure([], () => r(require('@/pages/hospital/article-detail.vue')), 'ArticleDetail');
const DeptDetail = r => require.ensure([], () => r(require('@/pages/hospital/dept-detail.vue')), 'DeptDetail');
const HospitalSearch = r => require.ensure([], () => r(require('@/pages/hospital/hospital-search.vue')), 'HospitalSearch');
const DoctorSearch = r => require.ensure([], () => r(require('@/pages/hospital/doctor-search.vue')), 'DoctorSearch');
export default [
    {
        path: '/hospital',
        component: View,
        children: [
            {
                path: 'hospital-list',
                component: HospitalList
            },
            {
                path: 'hospital-detail',
                component: HospitalDetail
            },
            {
                path: 'doctor-list',
                component: DoctorList
            },
            {
                path: 'doctor-detail',
                component: DoctorDetail
            },
            {
                path: 'channel-list',
                component: ChannelList
            },
            {
                path: 'article-detail',
                component: ArticleDetail
            },
            {
                path: 'dept-detail',
                component: DeptDetail
            },
            {
                path: 'hospital-search',
                component: HospitalSearch
            },
            {
                path: 'doctor-search',
                component: DoctorSearch
            }
        ]
    }
];

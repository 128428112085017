export default [
    {
        path: '/healthAssessment',
        name: 'mco-healthAssessment',
        meta: {
            disableShare: true,
            requiresAuth: true,
            title: '健康评估'
        },
        component: () => import('@/pages/healthAssessment/index.vue')
    },
    {
        path: '/healthAssessment/report',
        name: 'mco-healthAssessment-report',
        meta: {
            disableShare: true,
            requiresAuth: true,
            title: '健康评估报告'
        },
        component: () => import('@/pages/healthAssessment/report.vue')
    }
];
/* eslint-disable import/extensions */

import View from 'components/view';

const OldChannel = r => require.ensure([], () => r(require('@/pages/wisdomHelpsTheAged/old-channel.vue')), 'OldChannel');
const OldList = r => require.ensure([], () => r(require('@/pages/wisdomHelpsTheAged/old-list.vue')), 'OldList');
const OldDetail = r => require.ensure([], () => r(require('@/pages/wisdomHelpsTheAged/old-detail')), 'OldDetail');

export default [
    {
        path: '/old-action',
        component: View,
        children: [
            {
                name: 'oldActionIndex',
                path: 'index',
                component: OldList
            },
            {
                path: 'detail',
                component: OldDetail
            },
            {
                path: 'channel',
                component: OldChannel
            }
        ]
    }
];

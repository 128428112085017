
import View from 'components/view';

const ApplyCard = r => require.ensure([], () => r(require('@/pages/healthCard/apply-card.vue')), 'ApplyCard');
const RegisterBindCard = r => require.ensure([], () => r(require('@/pages/healthCard/register-bind-card.vue')), 'RegisterBindCard');
const RegisterBindCardDetail = r => require.ensure([], () => r(require('@/pages/healthCard/register-bind-card-detail.vue')), 'RegisterBindCardDetail');
const RegisterBindCardContact = r => require.ensure([], () => r(require('@/pages/healthCard/register-bind-card-contact.vue')), 'RegisterBindCardContact');
const CardManageList = r => require.ensure([], () => r(require('@/pages/healthCard/card-manage-list.vue')), 'CardManageList');
const CardManageDetail = r => require.ensure([], () => r(require('@/pages/healthCard/card-manage-detail.vue')), 'CardManageDetail');
const SearchItem = r => require.ensure([], () => r(require('@/pages/healthCard/search-item.vue')), 'SearchItem');

export default [
    {
        path: '/health-card',
        component: View,
        children: [
            {
                path: 'apply',
                component: ApplyCard
            },
            {
                path: 'register-bind',
                component: RegisterBindCard
            },
            {
                path: 'register-bind-detail',
                component: RegisterBindCardDetail
            },
            {
                path: 'register-bind-contact',
                component: RegisterBindCardContact
            },
            {
                path: 'card-manage-list',
                component: CardManageList
            },
            {
                path: 'card-manage-detail',
                component: CardManageDetail
            },
            {
                path: 'search-item',
                component: SearchItem
            }
        ]
    }
];

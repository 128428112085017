
import View from 'components/view';

const OnlineVisit = r => require.ensure([], () => r(require('@/pages/epidemic/online-visit.vue')), 'OnlineVisit');
const HljHosImg = r => require.ensure([], () => r(require('@/pages/epidemic/hlj-hos-img.vue')), 'HljHosImg');
const HljArticle = r => require.ensure([], () => r(require('@/pages/epidemic/hlj-article')), 'HljArticle');
const PreventServer = r => require.ensure([], () => r(require('@/pages/epidemic/prevention-server')), 'PreventServer');
const EpidemicIndex = r => require.ensure([], () => r(require('@/pages/epidemic/epidemic-index')), 'EpidemicIndex');
const HljQRcode = r => require.ensure([], () => r(require('@/pages/epidemic/hlj-QRcode.vue')), 'HljQRcode');

export default [
    {
        path: '/epidemic',
        component: View,
        children: [
            {
                path: 'index',
                component: EpidemicIndex
            },
            {
                path: 'online-visit',
                component: OnlineVisit
            },
            {
                path: 'hljhos-img',
                component: HljHosImg
            },
            {
                path: 'hlj-article',
                component: HljArticle
            },
            {
                path: 'prevent-server',
                component: PreventServer
            },
            {
                path: 'hlj-QRcode',
                component: HljQRcode
            }
        ]
    }
];
